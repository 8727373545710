import CustomText from '../../utilities/CustomText/CustomText';
import './infoblock.scss'

function infoblock() {
    return (
        <section className="infoblock">
            <div className='container'>
                <div className='infoblock_wrap'>
                    <h1>Политика обработки персональных данных</h1>
                    <div className='infoblock_wrap_box'>
                        <h2>1. Общие положения</h2>
                        <CustomText className='small'>
                            <p>1.1. Настоящая Политика разработана во исполнение требований Конституции Российской Федерации, п. 2 ч. 1 и ч. 2 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» (далее — Закон о персональных данных), Трудового кодекса Российской Федерации, Федерального закона от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации» и с учетом положений действующего законодательства. Настоящая Политика определяет основные принципы, цели, условия и способы обработки персональных данных, перечни субъектов обработки персональных данных и категории обрабатываемых Оператором персональных данных, права и обязанности Оператора при обработке персональных данных, права субъектов персональных данных.</p>
                            <p>1.2. Настоящая Политика действует в отношении всех обрабатываемых персональных данных, полученных как до издания соответствующей Политики, так и после, за исключением случаев, когда положения настоящей Политики не могут быть распространены на отношения по обработке и защите персональных данных, полученных до ее утверждения.</p>
                        </CustomText>

                        <h2>2. Термины:</h2>
                        <CustomText className='small'>
                            <p><b>Персональные данные</b> (ПД) — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</p>
                            <p><b>Оператор</b> – ООО «Лидс Лаб» (ОГРН 1177746582463, 127015, г. Москва, пр-д 4-й Вешняковский, д. 5, к. 2, кв. 68.), юридическое лицо, самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</p>
                            <p><b>Обработка ПД</b> — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПД;</p>
                            <p><b>Автоматизированная обработка ПД</b> — обработка ПД с помощью средств вычислительной техники;</p>
                            <p><b>Распространение ПД</b> — действия, направленные на раскрытие ПД неопределенному кругу лиц;</p>
                            <p><b>Предоставление ПД</b> — действия, направленные на раскрытие ПД определенному лицу или определенному кругу лиц;</p>
                            <p><b>Блокирование ПД</b> — временное прекращение обработки ПД (за исключением случаев, если обработка необходима для уточнения ПД);</p>
                            <p><b>Уничтожение ПД</b> — действия, в результате которых становится невозможным восстановить содержание ПД в информационной системе ПД и (или) в результате которых уничтожаются материальные носители ПД;</p>
                            <p><b>Обезличивание ПД</b> — действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность ПД конкретному субъекту;</p>
                            <p><b>Информационная система ПД</b> — совокупность содержащихся в базах данных ПД и обеспечивающих их обработку информационных технологий и технических средств;</p>
                            <p><b>Трансграничная передача ПД</b> — передача ПД на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
                        </CustomText>

                        <h2>3. Основные принципы обработки персональных данных</h2>
                        <CustomText className='small'>
                            <p>3.1. Обработка ПД основывается на принципе законности обработки ПД. Обработка ограничивается достижением конкретных, заранее определенных и законных целей.</p>
                            <p>3.2. При обработке ПД обеспечивается их точность, достаточность и актуальность.</p>
                            <p>3.3. Обработанные ПД подлежат уничтожению после достижения целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом, а также по заявлению субъекта ПД.</p>
                        </CustomText>

                        <h2>4. Основные цели обработки персональных данных</h2>
                        <CustomText className='small'>
                            <p>4.1. Одной из основных целей обработки ПД является подготовка, заключение, исполнение и прекращение гражданско-правовых договоров с контрагентами.</p>
                            <p>4.2. Осуществляя обработку ПД, Оператор обеспечивает соблюдение требований законодательства Российской Федерации.</p>
                            <p>4.3. Настоящим Оператор гарантирует соблюдение конфиденциальности информации при обработке ПД.</p>
                            <p>4.4. Обработка ПД допускается при наличии явно выраженного согласия субъекта ПД либо получена от третьего лица с получением согласия субъекта ПД.</p>
                            <p>4.5. Не допускается обработка ПД, которая не соответствует законным целям.</p>
                        </CustomText>

                        <h2>5. Объем и категории обрабатываемых ПД, категории субъектов ПД</h2>
                        <CustomText className='small'>
                            <p>5.1. Оператор обрабатывает персональные данные следующих категорий субъектов персональных данных:</p>
                            <p>5.1.1. ПД клиентов, партнеров и контрагентов Оператора (физических лиц), а также работников/представителей юридического лица, являющегося клиентом или контрагентом Оператора – информация, необходимая Оператору для выполнения своих обязательств в рамках договорных отношений;</p>
                            <p>5.1.2. ПД иных лиц – информация, необходимая Оператору для реализации своих прав и законных интересов в рамках осуществления видов деятельности, предусмотренных Уставом и иными локальными нормативными актами.</p>
                            <p>5.2. Оператор обрабатывает следующие категории персональных данных:</p>
                            <p>— пол;</p>
                            <p>— возраст;</p>
                            <p>— фамилию, имя и отчество;</p>
                            <p>— паспортные данные;</p>
                            <p>— номера телефонов;</p>
                            <p>— адреса электронных почт;</p>
                            <p>— информацию о предпочтениях;</p>
                            <p>— cookies-файлы;</p>
                            <p>— сведения о родственниках;</p>
                            <p>— любые общедоступные персональные данные;</p>
                            <p>— фотографии.</p>
                        </CustomText>

                        <h2>6. Права и обязанности Оператора</h2>
                        <CustomText className='small'>
                            <p>6.1. Оператор вправе поручить обработку ПД третьим лицам при условии предварительного получения согласия субъекта ПД, если иное не установлено законом.</p>
                            <p>6.2. Оператор вправе получать ПД от третьих лиц при условии уведомления Оператором субъекта ПД о начале осуществления обработки его ПД и/или получения согласия на обработку ПД от субъекта ПД.</p>
                            <p>6.3. Оператор обязуется предоставлять субъекту ПД запрашиваемую информацию в соответствии с настоящей Политикой.</p>
                            <p>6.4. Оператор обязуется обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ПД граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, при сборе ПД, в том числе посредством информационно-телекоммуникационной сети «Интернет».</p>
                            <p>6.5. Оператор обязуется не раскрывать третьим лицам и не распространять ПД без согласия субъектов ПД.</p>
                            <p>6.6. В случае, если Оператор осуществляет обработку ПД по поручению другого оператора, то Оператор не обязан получать согласие субъекта ПД на обработку его ПД.</p>
                        </CustomText>

                        <h2>7. Права субъектов персональных данных</h2>
                        <CustomText className='small'>
                            <p>7.1. Субъект вправе получать информацию, предусмотренную законодательством Российской Федерации, по предварительному запросу, касающуюся обработки его ПД.</p>
                            <p>7.2. Субъект вправе требовать от Оператора уточнения его ПД, их блокирования или уничтожения в случае, если ПД являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                            <p>7.3. Субъект вправе отозвать согласие на обработку ПД.</p>
                        </CustomText>

                        <h2>8. Порядок обработки персональных данных</h2>
                        <CustomText className='small'>
                            <p>8.1. Оператор осуществляет автоматизированную, смешанную и не автоматизированную обработку ПД в соответствии с законодательством Российской Федерации.</p>
                            <p>8.2. Оператор получает ПД в устной и/или письменной форме непосредственно от субъектов ПД; и/или от третьих лиц при условии соблюдения порядка получения согласия на обработку ПД; и/или из общедоступных источников.</p>
                            <p>8.3. Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других несанкционированных действий, в том числе</p>
                            <p>8.4. При сборе ПД, в том числе посредством информационно-телекоммуникационной сети Интернет, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ПД граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.</p>
                            <p>8.5. Трансграничная передача ПД на территории иностранных государств, являющихся сторонами Конвенции о защите физических лиц при автоматизированной обработке персональных данных, а также иных иностранных государств, обеспечивающих адекватную защиту прав субъектов персональных данных, осуществляется в соответствии с Федеральным законом «О персональных данных» и может быть запрещена или ограничена в целях защиты основ конституционного строя Российской Федерации, нравственности, здоровья, прав и законных интересов граждан, обеспечения обороны страны и безопасности государства. Трансграничная передача персональных данных на территорию иностранного государства, не являющегося стороной указанной Конвенции, осуществляется в соответствии с законодательными актами Российской Федерации при условии соответствия действующих в этом государстве норм права и применяемых мер безопасности персональных данных положениям Конвенции.</p>
                            <p>8.6. Хранение ПД осуществляется в течение срока, необходимого для достижения целей обработки и в форме, позволяющей с полной степенью достоверности определить субъекта ПД, за исключением обработки ПД способом обезличивания.</p>
                            <p>8.7. Субъект персональных данных может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:info@reffection.com">info@reffection.com</a>.</p>
                        </CustomText>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default infoblock;