import React from "react";
import { Helmet } from "react-helmet-async";
import { getMetaConfig } from "../../../config/metaConfig";

const MetaTags = () => {
   const currentDomain = window.location.hostname.split('.');
   const { cityIn, city, address, phone, domain } = getMetaConfig(currentDomain);

   return (
      <Helmet>
         <link rel="canonical" href={`https://${domain}reffection.ru/`} />
         <title>{`Лидогенерация для бизнеса ${cityIn} - Reffection`}</title>
         <meta name="title" content={`Лидогенерация для бизнеса ${cityIn} - Reffection`} />
         <meta name="description"
            content={`Лидогенерация для бизнеса ${cityIn}. Reffection - MarTech платформа с технологией Big Data и собственной DMP платформой. Подробности на сайте.`} />

         <meta property="og:url" content={`https://${domain}reffection.ru/`} />
         <meta property="og:title" content={`Лидогенерация для бизнеса ${cityIn} - Reffection`} />
         <meta property="og:description"
            content={`Лидогенерация для бизнеса ${cityIn}. Reffection - MarTech платформа с технологией Big Data и собственной DMP платформой. Подробности на сайте.`} />

         <meta property="twitter:url" content={`https://${domain}reffection.ru/`} />
         <meta property="twitter:title" content={`Лидогенерация для бизнеса ${cityIn} - Reffection`} />
         <meta property="twitter:description"
            content={`Лидогенерация для бизнеса ${cityIn}. Reffection - MarTech платформа с технологией Big Data и собственной DMP платформой. Подробности на сайте.`} />

         <script type="application/ld+json">
            {`
            {
               "@context": "http://schema.org",
               "@type": "Organization",
               "name": "Reffection",
               "telephone": "${phone}",
               "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${address}",
                  "addressLocality": "${city}",
                  "addressCountry": "RU"
               }
            }
            `}
         </script>
      </Helmet>
   );
};

export default MetaTags;
