import { Link } from 'react-router-dom';
import CustomText from '../utilities/CustomText/CustomText';
import './ConsentInfo.scss'

function ConsentInfoSecond() {
   return (
      <section className="infoblock consent">
         <div className='container'>
            <div className='infoblock_wrap'>
               <h1>Согласие посетителя сайта на обработку персональных данных</h1>
               <div className='infoblock_wrap_box'>
                  <CustomText className="small">
                     <p>Продолжая использовать сайт, я соглашаюсь на обработку моих персональных данных любыми способами, в том числе третьими лицами, в том числе воспроизведение, электронное копирование, обезличивание, блокирование, уничтожение, а также вышеуказанную обработку иных моих персональных данных, полученных в результате их обработки.</p>
                     <p>Я осознаю, что мои персональные данные содержатся или могут содержаться в файлах Сookie, установленных на сайте сервисом Reffection (ООО "ВИА ПУШ" - ИНН 7734403888). Обработка моих персональных данных осуществляется для проведения анализа данных и осуществления статистических, аналитических и иных исследований на основе обезличенных данных, анализа эффективности рекламы, распространения рекламы, предоставления пользователю доступа к персонализированным ресурсам сайта, установления с пользователем обратной связи, осуществления звонков, направления уведомлений, запросов, касающихся использования сайта, совершенствования продуктов и услуг Владельца сайта, определения предпочтений, информирования пользователя об услугах Владельца сайта, обеспечения технической возможности функционирования сайта, а также для передачи информации рекламного характера, других информационных сообщений, что впоследствии позволяет улучшать работу сайта.</p>
                     <p>Я выражаю свое согласие Публичному акционерному обществу «Мобильные ТелеСистемы» (ПАО «МТС»), адрес места нахождения: г. Москва, ул. Марксистская, д. 4 на предоставление ими третьим лицам, сведений обо мне как абоненте<sup>[1]</sup> и оказываемых мне услугах связи по договору об оказании услуг связи, заключенному с таким оператором связи.</p>
                     <p>Я выражаю свое согласие Публичному акционерному обществу «МегаФон» (ПАО «МегаФон»), адрес места нахождения: г. Москва, пер. Оружейный, д. 41 на предоставление ими третьим лицам, сведений обо мне как абоненте<sup>[1]</sup> и оказываемых мне услугах связи по договору об оказании услуг связи, заключенному с таким оператором связи.</p>
                     <p>Я выражаю свое согласие Публичному акционерному обществу «ВымпелКом» (ПАО «ВымпелКом»), адрес места нахождения: г. Москва, ул. 8 Марта, д. 10, стр. 14 на предоставление ими третьим лицам, сведений обо мне как абоненте<sup>[1]</sup> и оказываемых мне услугах связи по договору об оказании услуг связи, заключенному с таким оператором связи.</p>
                     <p>Я выражаю свое согласие Публичному акционерному обществу «Ростелеком» (ПАО «Ростелеком»), адрес места нахождения: Москва, ул. Гончарная, д. 30 на предоставление ими третьим лицам, сведений обо мне как абоненте<sup>[1]</sup> и оказываемых мне услугах связи по договору об оказании услуг связи, заключенному с таким оператором связи.</p>
                     <p>Я выражаю свое согласие Обществу с ограниченной ответственностью «Т2 Мобайл» (ООО «Т2 Мобайл»), адрес места нахождения: Москва, км 22-Й (Киевское Ш.), двлд. 6 стр. 1, этаж 5 комната 33 на предоставление ими третьим лицам, сведений обо мне как абоненте<sup>[1]</sup> и оказываемых мне услугах связи по договору об оказании услуг связи, заключенному с таким оператором связи.</p>
                     <p>Файлы Сookie – это файлы, хранящиеся на устройстве пользователя, через которое осуществляется доступ к сайту, и содержащие информацию о прошлых посещениях сайта, сайтах и запросах, по которым пользователь перешёл на сайт, присвоенных идентификаторах (ID), IP-адресе, сведения о местоположении, типе устройства, номере мобильного телефона, дате и времени сессии, сведения о действиях на сайте.</p>
                     <p>Я подтверждаю, что ознакомился с Политикой обработки персональных данных Reffection, расположенной по адресу: <Link to="/privacy-second">https://reffection.ru/privacy-second</Link></p>
                     <p>Я подтверждаю, что ознакомлен с перечнем действий, которые совершает сервис Reffection с персональными данными, на совершение которых дается согласие: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ, в т.ч. трансграничные), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                     <p>Я осознаю, что обработка вышеуказанных персональных данных будет осуществляться путем автоматизированной обработки персональных данных.</p>
                     <p>Я проинформирован о возможности отказа от обработки моих персональных данных, находящихся в файлах Cookie, путем прекращения использования сайта или отключения файлов Cookie в настройках браузера.</p>
                     <p>Настоящее согласие действует в течение неопределенного срока до момента достижения целей обработки или до момента его отзыва.</p>
                     <p>Согласие может быть отозвано в любое время путем подачи Оператору заявления в простой письменной форме. Я подтверждаю, что в случае отзыва настоящего Согласия Оператор вправе обрабатывать персональные данные в случаях и в порядке, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
                     <hr />
                     <p>[1] Сведения об абоненте: адрес абонента или адрес установки оконечного оборудования, информация о нагрузке, оказываемой абонентским оборудованием на сеть подвижной связи, абонентские номера и другие данные, позволяющие идентифицировать абонента или его оконечное оборудование, сведения баз данных систем расчета за оказанные услуги связи.</p>
                  </CustomText>
               </div>
            </div>
         </div>
      </section>
   );
}

export default ConsentInfoSecond;